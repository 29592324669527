import React from 'react'
import Container from './container'
import Header from './header'
import Footer from './footer'
import '../styles/critical.scss'

function Template(props) {
  const { children } = props

  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`
  }

  return (
    <Container>
      <Header />
      <main>{children}</main>
      <Footer />
    </Container>
  )
}

export default Template
