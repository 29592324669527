import React from 'react'

function Header() {
  return (
    <header className="header">
      <div className="site-wide grid-container grid-container--half grid-container--no-stack">
        <div className="grid-column">
          <a href="/" className="header__home">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              enableBackground="new 0 0 94.5 94.5"
              version="1.1"
              viewBox="0 0 94.5 94.5"
              xmlSpace="preserve"
            >
              <g>
                <g stroke="#000">
                  <path
                    fill="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.026"
                    d="M61.8 44.2H64.1V59.800000000000004H61.8z"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.026"
                    d="M51.3 29.4h23.6c.3 0 .5.3.5.5v.3l-11.3 14h-2.3l-11-14.1c0-.2 0-.5.5-.7-.3 0-.3 0 0 0z"
                  ></path>
                  <path
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.026"
                    d="M60.6 42.5L65.1 42.5"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.026"
                    d="M70.9 62.1c-2.5-.3-4.8-1-6.8-2.3h-2.3c-2 1.3-4.5 2-6.8 2.3 0 0-.5 1.3 7.8 1.3h.5c8.3-.1 7.6-1.3 7.6-1.3z"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.612"
                    d="M60.1 60.8c2 .8 4 .8 6 0"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.17"
                    d="M35.7 65.8H22.3l-3.3-2 1-33.7c0-.5.5-1 1-1h16.3c.5 0 1 .5 1 1l.8 33.7-3.4 2z"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeMiterlimit="10"
                    strokeWidth="1.17"
                    d="M47 36.7c-.8-1.3-2-2-3.5-2-1.8 0-3.8.5-5.3 1 0 .5.5 3.3.5 3.5.3 0 0 0 .3-.3 1.5-.8 3-1.3 4.8-1.3 0 0 .5.3 1 .8 1.5 2 3 8 0 17.1-1.5 1-3.5.8-4.8-.3-.3 0-.8-.5-.8-.5v3.8c1 .3 2 .5 3 .8 1.8 0 3.3-.5 4.8-1.8.3-.3.5-.5.5-.8 3.3-9.5 1.7-16.8-.5-20z"
                  ></path>
                  <ellipse
                    cx="29.1"
                    cy="61.1"
                    fill="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.17"
                    rx="10.1"
                    ry="1"
                  ></ellipse>
                  <path
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.702"
                    d="M22.3 65.8L21.8 32.7"
                  ></path>
                  <path
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.702"
                    d="M35.7 65.8L35.9 45.2"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeMiterlimit="10"
                    strokeWidth="1.386"
                    d="M53.3 23.6H42.2c-.3 0-.5.3-.5.5 0 2.5-.5 16.8 3.8 26.4h4c4.3-9.3 4-23.9 3.8-26.4.2-.5 0-.5 0-.5z"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeMiterlimit="10"
                    strokeWidth="1.386"
                    d="M49.7 66.3c-.8.3-1.3.3-2 .3-.8 0-1.5 0-2-.3 0 0 .8-1.8.8-8s-.8-8-.8-8h4c-.5 2.8-.8 5.3-.8 8 .1 2.8.3 5.5.8 8z"
                  ></path>
                  <path
                    fill="#FFF"
                    strokeMiterlimit="10"
                    strokeWidth="1.386"
                    d="M53 68.3c-1.3-.3-2.3-1-3.3-1.8-.8.3-1.3.3-2 .3-.8 0-1.5 0-2-.3-1 .8-2 1.5-3.3 1.8-1.8.5-1.8 2.5 5.3 2.5h0c7.1.1 7.1-2.2 5.3-2.5z"
                  ></path>
                  <circle
                    cx="47.2"
                    cy="47.2"
                    r="39.5"
                    fill="none"
                    strokeMiterlimit="10"
                    strokeWidth="1.35"
                  ></circle>
                  <circle
                    cx="47.2"
                    cy="47.2"
                    r="45"
                    fill="none"
                    strokeMiterlimit="10"
                    strokeWidth="1.35"
                  ></circle>
                </g>
              </g>
            </svg>
          </a>
        </div>

        <div className="grid-column">
          <div className="social-icons">
            {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon social-icon--twitter">
              <svg viewBox="0 0 32.33 26.28"><g id="Layer_2" data-name="Layer 2"><g id="OBJECTS"><path d="M29,6.54c.42,9.33-6.54,19.74-18.86,19.74A18.8,18.8,0,0,1,0,23.29a13.29,13.29,0,0,0,9.82-2.74,6.65,6.65,0,0,1-6.2-4.61,6.56,6.56,0,0,0,3-.11A6.63,6.63,0,0,1,1.3,9.24a6.73,6.73,0,0,0,3,.84A6.65,6.65,0,0,1,2.25,1.21,18.83,18.83,0,0,0,15.92,8.15,6.64,6.64,0,0,1,27.23,2.1,13.35,13.35,0,0,0,31.44.48a6.6,6.6,0,0,1-2.92,3.67,13.42,13.42,0,0,0,3.81-1A13.34,13.34,0,0,1,29,6.54Z"/></g></g></svg>
          </a> */}

            <a
              href="https://www.instagram.com/all.the.booze/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon social-icon--insta"
            >
              <svg width="20" viewBox="0 0 20 20.9">
                <g>
                  <path d="M19.4,6.6c0-1-0.2-1.7-0.4-2.3c-0.2-0.6-0.6-1.2-1.1-1.7c-0.5-0.5-1-0.9-1.7-1.1c-0.6-0.2-1.3-0.4-2.3-0.4 C12.9,1,12.6,1,10,1S7.1,1,6.1,1.1c-1,0-1.7,0.2-2.3,0.4C3.2,1.8,2.6,2.1,2.2,2.6c-0.5,0.5-0.9,1-1.1,1.7C0.8,4.9,0.7,5.6,0.6,6.6 c0,1-0.1,1.3-0.1,3.9s0,2.9,0.1,3.9c0,1,0.2,1.7,0.4,2.3c0.2,0.6,0.6,1.2,1.1,1.7c0.5,0.5,1,0.9,1.7,1.1c0.6,0.2,1.3,0.4,2.3,0.4 c1,0,1.3,0.1,3.9,0.1c2.6,0,2.9,0,3.9-0.1c1,0,1.7-0.2,2.3-0.4c1.3-0.5,2.3-1.5,2.8-2.8c0.2-0.6,0.4-1.3,0.4-2.3 c0-1,0.1-1.3,0.1-3.9S19.4,7.6,19.4,6.6z M17.7,14.3c0,0.9-0.2,1.4-0.3,1.7c-0.3,0.8-1,1.5-1.8,1.8c-0.3,0.1-0.8,0.3-1.7,0.3 c-1,0-1.3,0.1-3.8,0.1c-2.5,0-2.8,0-3.8-0.1c-0.9,0-1.4-0.2-1.7-0.3c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.6-0.7-0.7-1.1 c-0.1-0.3-0.3-0.8-0.3-1.7c0-1-0.1-1.3-0.1-3.8c0-2.5,0-2.8,0.1-3.8c0-0.9,0.2-1.4,0.3-1.7c0.2-0.4,0.4-0.8,0.7-1.1 C3.7,3.5,4,3.3,4.5,3.1C4.8,3,5.3,2.8,6.2,2.8c1,0,1.3-0.1,3.8-0.1c2.5,0,2.8,0,3.8,0.1c0.9,0,1.4,0.2,1.7,0.3 c0.4,0.2,0.8,0.4,1.1,0.7c0.3,0.3,0.6,0.7,0.7,1.1c0.1,0.3,0.3,0.8,0.3,1.7c0,1,0.1,1.3,0.1,3.8C17.7,13,17.7,13.3,17.7,14.3z" />
                  <path d="M10,5.6c-2.7,0-4.8,2.2-4.8,4.8s2.2,4.8,4.8,4.8c2.7,0,4.8-2.2,4.8-4.8S12.7,5.6,10,5.6z M10,13.6c-1.7,0-3.1-1.4-3.1-3.1 c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1C13.1,12.2,11.7,13.6,10,13.6z" />
                  <path d="M15,4.3c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C16.2,4.8,15.7,4.3,15,4.3z" />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
